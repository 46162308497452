.placeHolder {
    height: 56px;

    .chatNotice {
        position: fixed;
        background-color: #450A0A;
        border-radius: 8px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: #d4d4d4;
        margin: 0 16px;
        padding: 8px 16px;
    }
}