.placeHolder {
    height: 64px;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0 16px;
        height: 64px;
        box-sizing: border-box;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1;
            overflow: hidden;

            .backBtn {
                flex-shrink: 1;
            }

            .cpInfoBar {
                display: flex;
                align-items: center;
                flex: 1;
                overflow: hidden;
                gap: 8px;

                .info {
                    flex: 1;
                    line-height: 20px;
                    overflow: hidden;

                    .nameBar {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .name {
                            max-width: calc(100% - 16px);
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .online {
                            background-color: #84cc16;
                            width: 8px;
                            height: 8px;
                            border-radius: 99em;
                        }
                    }

                    .tag {
                        font-size: 14px;
                        color: #d4d4d4;
                        font-weight: 400;
                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
}
